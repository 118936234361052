import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import resume_icon from "../../assets/icons/resume-dark.svg"
import mail_icon from "../../assets/icons/mail-dark.svg"
import linkedin_icon from "../../assets/icons/linkedin-dark.svg"

const Wrapper = styled.div`
  width: max(18vw, 20rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  text-align: center;

  a {
    img {
      height: clamp(30%, 4vh, 50%);
      margin: 0;
      transition: opacity 0.5s;
    }
    p {
      opacity: 0;
      transition: opacity 0.5s;
      margin: 0;
      font-size: max(10px, 1vw);
    }
  }

  a:hover {
    color: #cccccc;
    p {
      opacity: 1;
    }
    img {
      opacity: 0.5;
    }
  }
`

const ContactBar = () => {
  const { desktop } = useStaticQuery(graphql`
    query {
      desktop: file(
        relativePath: { eq: "downloads/Antunes de Sa - Resume.pdf" }
      ) {
        publicURL
        name
      }
    }
  `)
  return (
    <Wrapper>
      <a href={desktop.publicURL} target="_blank" rel="noopener noreferrer">
        <img src={resume_icon} alt="Resume" />
        <p>Resume</p>
      </a>

      <a
        href="mailto:andre@fglaboratory.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={mail_icon} alt="Email" />
        <p>Email</p>
      </a>

      <a
        href="https://www.linkedin.com/in/andredesa/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={linkedin_icon} alt="LinkedIn Profile" />
        <p>LinkedIn</p>
      </a>
    </Wrapper>
  )
}
export default ContactBar
