import React from "react"
import { css, Global, ThemeProvider } from "@emotion/react"
import PropTypes from "prop-types"
import Header from "../components/Header"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import theme from "../../config/theme"

const Layout = ({ children, title, subtitle }) => (
  <ThemeProvider theme={theme}>
    <Global
      styles={css`
        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }
        html {
          text-rendering: optimizeLegibility;
          overflow-x: hidden;
          overflow-y: auto;
          box-sizing: border-box;
          -ms-overflow-style: scrollbar;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        html,
        body {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
        }
        body {
          background-color: ${theme.palette.background.base};
          color: ${theme.palette.primary.base};
          font-family: ${theme.fontFamily.body};
        }
        a {
          color: ${theme.colors.hlink.base};
          font-weight: bold;
          transition: color 0.5s;
          text-decoration: underline;
          text-decoration-thickness: 0.1rem;
        }
        a:hover {
          color: ${theme.colors.hlink.hover};
          text-decoration: underline;
          text-decoration-thickness: 0.1rem;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: ${theme.fontFamily.heading};
        }
        h1 {
          font-size: max(48px, 3.5vw);
          line-height: 110%;
          margin: 1rem 0 2rem 0;
          text-shadow: 0.12vw 0.1vw 0.1vw black;
        }
        h2 {
          font-size: max(22px, 2vw);
        }
        p {
          font-size: max(12px, 1.2vw);
        }
      `}
    />
    <SEO />
    <div css={css` height: 100vh; display: flex;  flex-direction: column;}`}>
      <Header title={title} subtitle={subtitle}></Header>
      {children}
      <Footer></Footer>
    </div>
  </ThemeProvider>
)

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

Layout.defaultProps = {
  children: false,
  title: false,
  subtitle: false,
}
