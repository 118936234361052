import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"
import ContactBar from "../components/ContactBar"

const BackgroundSection = ({ className, children }) => {
  const { desktop } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "images/habflight.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const imageData = desktop.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="BackgroundImg"
      className={className}
      fluid={imageData}
      backgroundColor={`#000000`}
    >
      {children}
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`

 width = 100%;
      height: calc(200px + 10vw);
      margin-bottom: -7%;
      z-index: 0;
      flex-shrink: 0;

      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 50% 70%;

     &:before, &:after {
      @media only screen and (max-width: 610px) {
        height: 340px !important;    
        background-size: cover !important;    
}}
  
`

const Title = styled.div`
  margin-left: 8%;
  margin-right: 8%;
  height: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & div:first-of-type {
    visibility: hidden;
    margin-top: auto;
  }

  & div:last-of-type {
    margin-bottom: auto;
  }
`

const Header = ({ children, title, subtitle }) => (
  <StyledBackgroundSection>
    <Title>
      <div>Hidden</div>
      <div>
        {title !== false && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
      </div>
      <div>{subtitle !== false && <h2>{subtitle}</h2>}</div>
      {subtitle === false && <ContactBar></ContactBar>}
      {children}
    </Title>
  </StyledBackgroundSection>
)

export default Header

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

Header.defaultProps = {
  children: false,
  title: false,
  subtitle: false,
}
