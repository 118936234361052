import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

const Copyright = styled.div`
  text-align: center;
  p {
    margin: 0;
    font-size: max(9px, 0.7vw);    
  }
`

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  )
  return (
    <Copyright>
      <p>{site.siteMetadata.author} &copy; 2021. All rights reserved.</p>
    </Copyright>
  )
}
export default Footer
