import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const Wrapper = styled.div`
  z-index: 1;
  width: 85%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`

const Container = ({ children }) => <Wrapper>{children}</Wrapper>

export default Container

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.object,
}
