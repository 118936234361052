import Typography from "typography"

export const typography = new Typography({
    baseFontSize: "16px",
    baseLineHeight: 1.5,
    googleFonts: [
        {
            name: "Roboto Condensed",
            styles: ["300", "300i"],
        },
        {
            name: "Roboto",
            styles: ["100", "100i", "300", "300i"],
        },
    ],
    headerFontFamily: ["Roboto Condensed", "sans-serif"],
    bodyFontFamily: ["Roboto", "sans-serif"],
    headerWeight: 300,
    bodyWeight: 100,
    boldWeight: 300,
})

export default typography
