import typography from "./typography"

export const colors = {
  white: {
    base: "#ffffff",
    light: "#f0f0f0",
    grey: "#cecece",
    dark: "#a0afd7",
  },
  black: {
    base: "#000000",
    light: "#4b4e57",
    blue: "#2e3246",
  },
  hlink: {
    base: "#87a4c4",
    hover: "#416083",
  },
}

export const palette = {
  primary: {
    base: colors.white.base,
  },
  background: {
    base: colors.black.base,
  },
}

export const theme = {
  colors,
  palette,
  breakpoints: {
    xs: "400px",
    s: "600px",
    m: "900px",
    l: "1200px",
  },
  fontFamily: {
    // eslint-disable-next-line
    body: typography.bodyFontFamily,
    // eslint-disable-next-line
    heading: typography.headerFontFamily,
  },
}

export default theme
